.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* Set the container height to fit the full viewport height */
}

.app-bar {
  background-color: #428C78;
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
}

.drawer-container {
  width: 16vw;
  flex: 0 0 auto;
  padding: 48px 28px 48px 0;
  background-color: #E9E9E9;
  border: 1px solid #E9E9E9;
  overflow-y: auto;
}

.check-container {
  background-color: #FAFAFA;
}

.page-content {
  display: flex;
  flex: 1;
  overflow-y: auto;
}

.drawer-category {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
}

.drawer-category.open {
  visibility: visible;
  opacity: 1;
}

.drawer-link {
  font-size: 16px;
  text-decoration: none;
  color: #626266;
}

.table-link {
  color: #0080F5;
  text-decoration: none;
}

.page-container {
  flex: 1;
  overflow-y: auto;
  padding: 24px 32px;
}

.page-container-hotels {
  flex: 1;
  padding: 24px 32px;
}

.login-container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  /*width: 400px;*/
  margin: auto;
  padding: 48px 100px;
}

.title {
  font-size: 40px;
  font-weight: 400;
  font-style: normal;
}

.title-secondary {
  font-size: 28px;
  font-weight: 600;
  font-style: normal;
}

.subtitle {
  font-size: 22px;
  font-weight: 600;
  color: #428C78;
}

.section-title {
  color: #2A2A2F;
  font-size: 16px;
  font-weight: 700;
}

.section-secondary-title {
  color: #2A2A2F;
  font-size: 14px;
  font-weight: 600;
}

.section-subtitle {
  color: #626266;
  font-size: 14px;
  font-weight: 400;
}

.xl-text {
  color: #2A2A2F;
  font-size: 64px;
  font-weight: 700;
}

.paragraph {
  /* overflow: hidden; */
  font-size: 16px;
}

.date {
  font-size: 14px;
  color: #999999;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}

.ellipsis-reviews {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}

.app-username {
  color: #FFF;
  font-weight: 600;
}

.app-role {
  color: #FFFFFF80;
}

.link {
  color: #161616;
  font-weight: bold;
}

.none-decoration-link {
  text-decoration: none;
}

.input-text {
  border: 1px solid #999999;
  background-color: #FAFAFA;
  padding: 12px 32px;
  border-radius: 6px;
  font-size: 16px;
  font-family: Visby;
}

.toggle {
  width: 50px;
  height: 25px;
  background-color: #DADADA;
  border-radius: 50px;
  padding: 5px;
}

.toggle.checked {
  background-color: #C8E5D7;
}

.toggle-span {
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background-color: #428C78;
  transition: 0.3s;
}

.toggle-span.checked {
  transform: translateX(25px);
}

.error-message {
  color: #DD172C;
  /* position: absolute; */
}

.search-bar-container {
  width: 100%;
  border: 1px solid #999999;
  border-radius: 8px;
  padding: 8px 32px;
  background-color: #FFFFFF33;
}

.search-bar {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  font-size: 18px;
  color: #FFF;
  text-align: center;
}

.search-bar::placeholder {
  color: #FFF;
}

.secondary-search-bar-container {
  width: 100%;
  border: 1px solid #999999;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: #F6F6F6;
}

.secondary-search-bar {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  font-size: 18px;
  color: #161616;
  text-align: center;
}

.primary-button {
  border: none;
  background-color: #428C78;
  padding: 12px 32px;
  border-radius: 8px;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.secondary-button {
  border: 1px solid #161616;
  background-color: transparent;
  padding: 12px 32px;
  border-radius: 8px;
  color: #161616;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.select-button {
  border: 1px solid #999999;
  background-color: transparent;
  padding: 8px;
  border-radius: 8px 8px 0 0;
  color: #161616;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  background-color: #FAFAFA;
  border: 1px solid #999999;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  top: 50px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  overflow: hidden;
}

.dropdown-menu.open {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.dropdown-item {
  background-color: transparent;
  padding: 8px;
  border: none;
  display: flex;
  /* width: 100%; */
}

.dropdown-item:hover {
  background-color: #DADADA;
}

/* Hide Mapbox attribution */
.mapboxgl-control-container {
  display: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.top-8px {
  top: -8px;
}

.top10 {
  top: 10px;
}

.right10 {
  right: 10px;
}

.right-8px {
  right: -8px;
}

.of-scroll {
  overflow: scroll;
}

.of-hidden {
  overflow: hidden;
}

.of-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ofx-hidden {
  overflow-x: hidden;
}

.ofx-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.ofx-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.info-modal {
  position: absolute;
  left: 32px;
  width: 250px;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 8px;
  visibility: hidden;
  opacity: 0;
}

.addevent-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 8px;
  visibility: hidden;
  opacity: 0;
}

.newevent-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 8px;
  visibility: hidden;
  opacity: 0;
}

.newmood-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 8px;
  visibility: hidden;
  opacity: 0;
}

.newstaff-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 8px;
  visibility: hidden;
  opacity: 0;
}

.info-modal.active {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.addevent-modal.active {
  visibility: visible;
  opacity: 1;
  z-index: 10;
  cursor: default;
}

.newevent-modal.active {
  visibility: visible;
  opacity: 1;
  z-index: 10;
  cursor: default;
}

.newmood-modal.active {
  visibility: visible;
  opacity: 1;
  z-index: 10;
  cursor: default;
}

.newstaff-modal.active {
  visibility: visible;
  opacity: 1;
  z-index: 10;
  cursor: default;
}

.close-icon {
  position: absolute;
  background-color: #DD172C;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 2px;
  top: 10px;
  right: 10px;
}

.loader {
  width: 50px;
  height: 50px;
  border: 10px solid #DADADA;
  border-top: 10px solid #428C78;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spin {
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fade-in {
  animation: fadein 0.5s linear forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.filter-darker {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: #00000080;
}

.opacity60 {
  filter: opacity(60%);
}

.select-menu {
  position: absolute;
  background-color: #F5F5F5;
  width: 100%;
  border-radius: 8px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  cursor: pointer;
  overflow: hidden;
}

.top50 {
  top: 60px;
}

.select-menu.open {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.select-item {
  padding: 12px 16px;
}

.select-item:hover {
  background-color: #DADADA;
}

.dashboard-section {
  border: 1px solid #E9E9E9;
  border-radius: 12px;
}

.gap20 {
  gap: 20px;
}

.border-container {
  border: 1px solid #DADADA;
}

.b {
  border: 1px solid #DADADA;
}

.bTransparent {
  border: 1px solid transparent;
}

.bWhite {
  border: 1px solid #FFFFFF;
}

.b-green {
  border-color: #428C78;
}

.b-dashed {
  border-style: dashed;
}

.b2 {
  border-width: 2px;
}

.bb {
  border-bottom: 1px solid #DADADA;
}

.brDADADA {
  border-right: 1px solid #DADADA;
}

.bb1616161px {
  border-bottom: 1px solid #161616;
  border-top: none;
  border-left: none;
  border-right: none;
}

.bb161616 {
  border-bottom: 2px solid #161616;
}

.b161616 {
  border: 1px solid #161616;
}

.bl {
  border-left: 1px solid #161616;
}

.r0 {
  border-radius: 0px;
}

.r100 {
  border-radius: 100px;
}

.r8 {
  border-radius: 8px;
}

.rr8 {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.rl8 {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.rr100 {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.r4 {
  border-radius: 4px;
}

.rt8 {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.rb8 {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.bgTransparent {
  background-color: transparent;
}

.bgRed {
  background-color: #E92C2C;
}


.bgWhite {
  background-color: white;
}

.bgDADADA {
  background-color: #DADADA;
}

.bgFAFAFA {
  background-color: #FAFAFA;
}

.bg428C78 {
  background-color: #428C78;
}

.bg7DCCA6 {
  background-color: #7DCCA6;
}

.bg161616 {
  background-color: #161616;
}

.bgE9E9E9 {
  background-color: #E9E9E9;
}

.bgF6F6F6 {
  background-color: #F6F6F6;
}

.bDADADA {
  border-color: #DADADA;
}

.bgLightGreen {
  background: var(--Harp, #E3F2EB);
}

.checkbox {
  width: 16px;
  height: 16px;
  border: 2px solid #428C78;
}

.checkbox-round {
  width: 16px;
  height: 16px;
  border-radius: 10px;
  border: 2px solid #428C78;
}

.checkbox-round.selected {
  background-color: #428C78;

}

.checkbox.selected {
  background-color: #428C78;
}

.tag {
  background-color: #F3F3F3;
  padding: 8px 12px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.tag.selected {
  background-color: #59B19F;
  color: #FFF;
}

.borderless {
  border: none;
}

.cursor-pointer {
  cursor: pointer;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex01 {
  flex: .1;
}

.flex02 {
  flex: .2;
}

.flex03 {
  flex: .3;
}

.flex04 {
  flex: .4;
}

.flex05 {
  flex: .5;
}

.flex06 {
  flex: .6;
}

.flex07 {
  flex: .7;
}

.flex08 {
  flex: .8;
}

.flex09 {
  flex: .9;
}

.flex-grow {
  flex-grow: 1;
}


.logo {
  width: 160px;
}

.logo-small {
  width: 60px;
}

.icon {
  width: 20px;
}

.icon50 {
  width: 50px;
}

.row-view {
  display: flex;
  flex-direction: row;
}

.grid-view2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid-view3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.none-outline {
  outline: none;
}

.wrap {
  flex-wrap: wrap;
}

.column-view {
  display: flex;
  flex-direction: column;
}

.column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}

.text-end {
  text-align: end;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.items-top {
  align-items: flex-start;
}

.self-end {
  align-self: flex-end;
}


.h100vh {
  height: 100vh;
}

.h20vh {
  height: 20vh;
}

.h25vh {
  height: 25vh;
}

.h64vh {
  height: 64vh;
}

.h70p {
  height: 80%;
}

.h100 {
  height: 100%;
}

.h70p {
  height: 80%;
}

.h25 {
  height: 25px;
}

.w90p {
  width: 90%;
}

.w80p {
  width: 80%;
}

.w60p {
  width: 60%;
}

.w100 {
  width: 100%;
}

.w120 {
  width: 120px;
}

.w160 {
  width: 160px;
}

.w200 {
  width: 200px;
}

.w250 {
  width: 250px;
}

.w300 {
  width: 300px;
}

.m20 {
  margin: 20px;
}

.mt05 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt50 {
  margin-top: 50px;
}

.mt100 {
  margin-top: 100px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.mr50 {
  margin-right: 50px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb50 {
  margin-bottom: 50px;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml50 {
  margin-left: 50px;
}

.ph10 {
  padding-right: 10px;
  padding-left: 10px;
}

.p10 {
  padding: 10px;
}

.p4 {
  padding: 4px;
}

.pv12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.ph32 {
  padding-left: 32px;
  padding-right: 32px;
}

.p20 {
  padding: 20px;
}

.pv20 {
  padding: 20px 0px;
}

.pv50 {
  padding: 50px 0;
}

.ph20 {
  padding-left: 20px;
  padding-right: 20px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb20 {
  padding-bottom: 20px;
}

.brdr8 {
  border-radius: 8px;
}

.size1 {
  font-size: 1.2rem
}

.size2 {
  font-size: 1.4rem
}

.size3 {
  font-size: 1.6rem
}

.size4 {
  font-size: 1.8rem
}

.size5 {
  font-size: 2rem
}

.color-grey {
  color: #a5a5a5;
}

.dark-grey {
  color: #343330;
}

.weight500 {
  font-weight: 500;
}

.weight600 {
  font-weight: 600;
}

.bold {
  font-weight: bold;
}

.green-text {
  color: #428C78;
}

.green-text-front {
  color: #428C78;
  z-index: 5;
}

.blue-text {
  color: #0085FF;
}

.green-text2 {
  color: #5E9A37;
}

.orange-text {
  color: #FF9F2D;
}

.red-text {
  color: #E92C2C;
}

.redbg01 {
  background-color: #E92C2C1A;
}

.orangebg01 {
  background-color: #FF9F2D1A;
}

.bluebg01 {
  background-color: #0085FF1A;
}

.black-text {
  color: #161616;
  font-weight: bold;
}

.white-text {
  color: #FFF;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.red-square {
  width: 16px;
  height: 16px;
  background-color: #E92C2C;
  border-radius: 4px;
}

.orange-square {
  width: 16px;
  height: 16px;
  background-color: #FF9F2D;
  border-radius: 4px;
}

.blue-square {
  width: 16px;
  height: 16px;
  background-color: #0085FF;
  border-radius: 4px;
}

.today {
  color: #428C78;
}

.today span {
  font-weight: normal;
}

.table {
  border-collapse: collapse;
  border-radius: 8px;
  /* overflow: hidden; */
  background: #FFFFFF;
  width: 100%;
  font-size: 16px;
  white-space: nowrap;
}

.table th,
.table td {
  padding: 10px 14px;
  text-align: left;

}

.table tr td {
  border-bottom: 1px solid #F6F6F6;
}

.thead tr {
  overflow: hidden;
}

.table th {
  background-color: #F6F6F6;
}

.table--name {
  display: flex;
  align-items: center;
  /* gap: 15px; */
}

.tbody tr td a {
  color: #0080F5;
}

.table--name img {
  border-radius: 15px;
}

.blur50 {
  filter: blur(4px);
}

.reservations--types {
  display: flex;
  justify-content: space-between;
  color: #929292;
}

.reservations--types p {
  display: flex;
  padding: 15px;
}

.reservations--types p:hover {
  cursor: pointer;
  color: #161616;
}

.notif {
  /* position: relative; */
  border-radius: 50px;
  background-color: #DD172C;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  color: white;
}

.h-separator {
  width: 100%;
  height: 1px;
}

.v-separator {
  height: 100%;
  width: 1px;
}

.purple-square16 {
  width: 16px;
  height: 16px;
  background-color: #7858FF;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 15px;
}

.pagination--item {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #565656;
  padding: 5px;
  border-radius: 5px;
  fill: #565656;
}

.pagination--item:hover {
  cursor: pointer;
}

.container--tickets {
  height: fit-content;
  width: 70vw;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ticket {
  border: 1px solid #DADADA;
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  min-height: 20vh;
  min-width: 70vw;
  background-color: #f3f3f3;
}

.ticket--titles {
  width: 18vw;
  min-height: 20vh;
  position: relative;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}

.ticket--content {
  min-height: 20vh;
  width: 52vw;
  position: relative;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}

.ticket--name {
  position: absolute;
  top: 0;
  left: 15px;
  font-weight: bold;
}

.ticket--author {
  position: absolute;
  top: 40px;
  left: 15px;
  color: #626266;
}

.ticket--label-urgent {
  position: absolute;
  bottom: 0px;
  left: 15px;
  padding: 5px;
  background: var(--light-background-red, linear-gradient(0deg, rgba(255, 59, 59, 0.10) 0%, rgba(255, 59, 59, 0.10) 100%), #FFF);
  border-radius: 3px;
}

.ticket--label-important {
  position: absolute;
  bottom: 0px;
  left: 15px;
  padding: 5px;
  background: var(--light-background-orange, linear-gradient(0deg, rgba(255, 150, 27, 0.10) 0%, rgba(255, 150, 27, 0.10) 100%), #FFF);
  border-radius: 3px;
}

.ticket--label-superficial {
  position: absolute;
  bottom: 0px;
  left: 15px;
  padding: 5px;
  border-radius: 3px;
  background: var(--light-background-blue, linear-gradient(0deg, rgba(0, 133, 255, 0.10) 0%, rgba(0, 133, 255, 0.10) 100%), #FFF);
}

.label--urgent {
  color: red;
}

.label--important {
  color: #FF9F2D;
}

.label--superficial {
  color: #0085FF;
}

.ticket--text {
  position: absolute;
  color: #626266;
  margin-left: 32px;
  margin-right: 20px;
  font-size: 16px;
}

.ticket--date {
  position: absolute;
  bottom: 32px;
  left: 32px;
  font-size: 12px;
  color: #626266;
}

.ticket--edit {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.ticket--share {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 1.5vh;
  right: 9vw;
  color: #428C78;
  font-weight: 600;
}

.ticket--share-icon {
  color: #428C78;
  font-size: 1.6rem;
  stroke-width: 2%;
}

.ticket--separator {
  box-sizing: content-box;
  height: 15vh;
  width: 1px;
  background-color: #161616;
  color: #161616;
  margin-top: 25px;
}

.reviews--top {
  display: flex;
  justify-content: flex-end;
}

.reviews {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.reviews--container {
  width: 55vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.reviews--average {
  width: 25vw;
  background: #F8F8F8;
}

.reviews--average-mark {
  background-color: #428C78;
  margin-left: 30px;
  height: 5vh;
  width: 5vh;
  font-size: 1.2rem;
  color: #FFF;
}

.review {
  border-radius: 8px;
  border: 1px solid #DADADA;
  height: 30vh;
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.review--stats {
  min-width: 35%;
  position: relative;
}

.review--separator {
  height: 25vh;
  margin-top: 20px;
  border: 1px solid #161616;
}

.review--mark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5vw;
  height: 5vh;
  border-radius: 4px;
  background: #428C78;
  color: white;
  font-weight: 700;
  margin-left: 35px;
}

.review--title {
  position: absolute;
  bottom: 50px;
  left: 30px;
}

.review--author {
  position: absolute;
  bottom: 20px;
  left: 30px;
  font-size: 0.8rem;
}

.review--score p span {
  font-weight: 10;
  color: rgb(223, 223, 223);
}

.review--text {
  position: absolute;
  color: #626266;
  margin-right: 30px;
  font-size: 16px;
}

.review--content {
  position: relative;
  min-width: 60%;
}

.review--date {
  position: absolute;
  bottom: 20px;
  color: #626266;
  font-size: 0.9rem;
}

.review--share {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 1.5vh;
  right: 11vw;
  color: #428C78;
  font-weight: 600;
}

.review--respond {
  position: absolute;
  right: 40px;
  bottom: 20px;
}

.review--mark-staff {
  position: relative;
  display: flex;
  flex-direction: column;
}

.review--mark-staff p {
  position: absolute;
  left: 30px;
  top: -15px;
  color: #525252;
}

.review--mark-staff p span {
  font-weight: bold;
  color: #428C78;
}

.review--first-mark-staff {
  min-width: 15vw;
  min-height: 4px;
  border-radius: 5px;
  border: 1px solid #DDDCDC;
  background-color: #DDDCDC;
  position: absolute;
  top: 3vh;
  left: 1.6vw;
}

.review--first-mark {
  min-width: 15vw;
  min-height: 4px;
  border-radius: 5px;
  border: 1px solid #DDDCDC;
  background-color: #DDDCDC;
  position: absolute;
}

.review--second-mark-staff {
  min-height: 4px;
  border-radius: 5px;
  border: 1px solid #428C78;
  background-color: #428C78;
  position: absolute;
  top: 3vh;
  left: 1.6vw;
}

.review--second-mark {
  min-height: 4px;
  border-radius: 5px;
  border: 1px solid #428C78;
  background-color: #428C78;
  position: absolute;
}

.review--first-mark-equipment {
  min-width: 15vw;
  min-height: 4px;
  border-radius: 5px;
  border: 1px solid #DDDCDC;
  background-color: #DDDCDC;
  position: absolute;
  top: 8vh;
  left: 1.6vw;
}


.review--second-mark-equipment {
  min-width: 12vw;
  max-width: 15vw;
  min-height: 4px;
  border-radius: 5px;
  border: 1px solid #428C78;
  background-color: #428C78;
  position: absolute;
  top: 8vh;
  left: 1.6vw;
}

.review--mark-equipment {
  position: relative;
  display: flex;
  flex-direction: column;
}

.review--mark-equipment p {
  position: absolute;
  left: 30px;
  top: 3.5vh;
  color: #525252;
}

.review--mark-equipment p span {
  font-weight: bold;
  color: #428C78;
}

.container--profile {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.top--profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.top--profile-left {
  position: relative;
  width: 30vw;
}

.top--profile-left h2 {
  position: absolute;
  left: 13vh;
  top: -1vh;
}

.top--profile-left p {
  position: absolute;
  left: 13vh;
  top: 3vh;
  color: #999999;
}

.top--profile-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2vh;
}

.profile--infos-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 30vh;
}

.profile--informations {
  width: 65%;
  border-radius: 12px;
  background: #F9F9F9;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  color: #161616;
}

.profile--destinations {
  width: 30%;
  border-radius: 12px;
  background: #F9F9F9;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  position: relative;
}

.profile--destinations-img {
  object-fit: contain;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-size: cover;
}

.profile--informations-title {
  position: absolute;
  color: #428C78;
  left: 2vw;
}

.profile--informations-email {
  position: absolute;
  top: 8vh;
  left: 2vw;
}

.profile--informations-textemail {
  position: absolute;
  top: 12vh;
  left: 2vw;
}

.profile--informations-birthdate {
  position: absolute;
  left: 50%;
  top: 8vh;
}

.profile--informations-textbirth {
  position: absolute;
  top: 12vh;
  left: 50%;
}

.profile--informations-phone {
  position: absolute;
  bottom: 5vh;
  left: 2vw;
}

.profile--informations-textphone {
  position: absolute;
  bottom: 2vh;
  left: 2vw;
}

.profile--informations-address {
  position: absolute;
  left: 50%;
  bottom: 5vh;
}

.profile--informations-textaddress {
  position: absolute;
  left: 50%;
  bottom: 2vh;
}

.profile--health {
  width: 100%;
  border-radius: 12px;
  background: #F9F9F9;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  color: #161616;
}

.profile--health h2 {
  position: absolute;
  left: 2vw;
}

.profile--communities {
  width: 100%;
  border-radius: 12px;
  background: #F9F9F9;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  color: #161616;
}

.profile--communities h2 {
  position: absolute;
  left: 2vw;
}

.profile--preferences {
  width: 100%;
  border-radius: 12px;
  background: #F9F9F9;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  color: #161616;
}

.horizontal--separator {
  width: 80%;
  border-radius: 5px;
  margin: auto;
  border: 1px solid #afafaf;
  ;
}

.profile--reservations-table {
  width: 90%;
  margin: auto;
}

.comment-textarea {
  min-width: 98%;
  min-height: 25vh;
  border: 1px solid #DADADA;
  resize: none;
  padding-top: 12px;
  padding-left: 16px;
}

.view--reservation-top {
  max-height: 10vh;
  margin-bottom: 10px;
}

.view--reservation-separator {
  width: 98%;
  height: 1px;
  background-color: #E9E9E9;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 20px;
}

.view--reservation-moon {
  height: fit-content;
  background-color: #C8E5D7;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  border-radius: 50px;
  gap: 5px;
  color: #428C78;
}

.view--reservation-room {
  width: 20%;
  height: 15vh;
}

.view--reservation-buttonmsg {
  width: 40%;
  height: 5vh;
}

.password-input-container {
  position: relative;
  display: inline-block;
}

.show-password-button {
  position: absolute;
  top: 50%;
  right: 5px;
  /* Ajustez cette valeur pour le positionnement horizontal */
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .drawer-link {
    font-size: 14px;
  }

  .section-title {
    font-size: 14px;
  }

  .purple-square16 {
    width: 12px;
    height: 12px;
  }

  .section-secondary-title {
    font-size: 12px;
  }

  .section-subtitle {
    font-size: 12px;
  }

  .highlight {
    position: absolute;
    bottom: 0;
    background-color: #FFFFFF33;
    width: 100%;
    height: 50%;
  }

  .xl-text {
    font-size: 32px;
  }

  .subtitle {
    font-size: 18px;
  }

  .paragraph {
    font-size: 14px;
  }

  .primary-button {
    padding: 8px 12px;
    font-size: 12px;
  }

  .secondary-button {
    padding: 8px 12px;
    font-size: 12px;
  }

  .search-bar-container {
    padding: 6px 12px;
  }

  .search-bar {
    font-size: 14px;
  }

  .secondary-search-bar-container {
    padding: 6px 12px;
  }

  .secondary-search-bar {
    font-size: 14px;
  }

  .table {
    font-size: 14px;
  }

  .table th,
  .table td {
    padding: 4px 8px;
  }

  .tag {
    font-size: 12px;
  }
}

@media screen and (max-width: 965px) {
  .size1 {
    font-size: .8rem;
  }

  .size2 {
    font-size: 1rem;
  }

  .reservations--types {
    font-size: 12px;
  }
}