.fc-event-time {
    display: none;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: transparent;
}

.fc-daygrid-day-top {
    flex-direction: row !important;
    margin-top: 3px;
    margin-left: 3px;
}

.fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    width: 26px;
    border-radius: 25px;
    background-color: #428C78;
    color: white;
    font-weight: bold;
}

.fc-view {
    margin-top: 30px;
}

tbody .fc-day {
    height: 10vh;
}

.fc-daygrid-day-events {
    z-index: 0;
}

.fc-daygrid-dot-event.fc-event-mirror, 
.fc-daygrid-dot-event:hover { /* remove grey background on hover */
    background: rgba(0, 0, 0, 0);
}

.green-background-text{
    content: "";
    background: #0ddf79;
    width: 40%;
    /* Ajustez la largeur selon vos besoins */
    height: 50%;
    /* Ajustez la hauteur selon vos besoins */
    position: absolute;
    top: 15px;
    left: 15px;
    /* Ajustez la valeur de décalage selon vos besoins */
    opacity: 0.1;

    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 5px;
    /* Ajustez l'opacité selon vos besoins */
}